@import "assets/sass/abstracts/mixin";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: linear-gradient(90deg, #FFDD79 0%, #C9DEB2 100%);
  padding: 19px 16px;
  @include mobile {
    padding: 10px 12px;
  }
}

.label {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: var(--color-primary);
  @include mobile {
    font-size: 14px;
    text-align: left;
    margin-right: 20px;
  }
}

.icon_placeholder {
  opacity: 0;
  pointer-events: none;
  @include mobile {
    position: absolute;
  }
}

.btn_close {
  background: transparent;
  outline: none;
  border: none;
}