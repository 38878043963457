// BASE
.w-full {
  width: 100%;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;

  &.items-end {
    align-items: flex-end;
  }

  &.no-wrap {
    flex-wrap: nowrap;
  }
}

.flex-1 {
  flex: 1;
}

.w-100 {
  width: 100px;
}

.text-center {
  text-align: center;
}

.items-center {
  &>* {
    margin-left: auto;
    margin-right: auto;
  }
}

.desktop-view {
  display: none !important;

  @include desktop {
    display: flex !important;
  }
}

.tablet-mobile-view {
  display: flex !important;

  @include desktop {
    display: none !important;
  }
}

.tablet-desktop-view {
  @include mobile {
    display: none !important;
  }
}

.mobile-view {
  display: none !important;

  @include mobile {
    display: block !important;
  }
}

.slider-control {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 140%;
  color: #949bda;
  padding-bottom: 8px;

  li {
    min-width: 30%;

    &:nth-child(2) {
      text-align: center;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.d-center {
  display: grid;
  gap: 8px;
  align-items: center;
  justify-content: center;

  .ant-btn-default {
    margin: auto;
  }
}

//COMMON

.common-btn,
.common-btn.ant-btn-default {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 170px;

  @include mobile {
    // min-width: 80px;
  }

  height: 42px;
  border: 2px solid var(--color-green);
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  background: var(--color-green);
  color: var(--color-white);
  transition: all 0.3s ease;
  cursor: pointer;
  // margin-bottom: 8px;

  .icon-calender-mobile {
    display: none;
  }

  @include mobile {
    flex: 1;

    &.no-flex {
      flex: 0;
      min-width: max-content;
      padding: 0 24px;
    }
  }

  a {
    color: var(--color-white);
  }

  &:hover {
    color: var(--color-green);
    background-color: var(--color-white);
    border-color: var(--color-green);

    a {
      color: var(--color-green);
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.is-white {
    color: var(--color-green);
    background-color: var(--color-white);
  }

  &.is-primary {
    color: var(--color-primary);
    border-color: var(--color-primary);
    background-color: var(--color-white);
  }

  &.is-outlined {
    color: var(--color-green);
    background-color: transparent;
  }

  &.is-center {
    margin-left: auto;
    margin-right: auto;
  }

  &.is-big {
    width: 205px;
  }

  &.is-brand {
    width: 100%;
  }
}

.common-scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d9d9d9;
  }
}

.btn-list-search {
  min-width: 64px;
  min-height: 65px;
  background: var(--icon-search-list) center/100% auto no-repeat;

  &.is-end {
    position: relative;
    bottom: -7px;
  }
}

.form-add {
  display: flex;
  align-items: flex-end;
  gap: 12px;

  .add-input {
    flex: 1;
  }

  .add-btn {
    display: flex;
    margin-bottom: 15px;
  }
}

.btn-delete-file {
  display: block;
  width: 42px;
  height: 42px;
  background: var(--icon-delete-red) center/contain no-repeat;
  border: none;
}

.common-image {
  width: 205px;
  height: auto;
  border-radius: 6px;
  overflow: hidden;
}

.common-action {
  display: flex;
  align-items: center;
  gap: 6px;
  transform: translateY(8px);
  margin-top: -10px;

  li,
  .action-item {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    overflow: hidden;
    background: center/contain no-repeat;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .action-favorite {
    background-image: var(--icon-action-favorite);

    &:hover,
    &.is-active {
      background-image: var(--icon-action-favorite-active);
    }
  }

  .action-send {
    background-image: var(--icon-action-send);
  }

  .action-remove {
    background-image: var(--icon-action-remove);
  }

  .action-edit {
    background-image: var(--icon-action-edit);
  }

  .action-info {
    background-image: var(--icon-action-info);
  }

  .action-export {
    background-image: var(--icon-action_export);
  }

  .action-view {
    background-image: var(--icon-action_view);
  }

  .action-more {
    background-image: var(--icon-action-more);
  }
}

// TAGS
.common-tag {
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  padding: 8px 20px;
  border-radius: 6px;

  &.is-primary {
    background: var(--color-primary-02);
    color: var(--color-primary);
  }

  &.is-red {
    background: var(--color-red-01);
    color: var(--color-red);
  }

  &.is-green {
    background: var(--color-primary-03);
    color: var(--color-green);

    &.is-active {
      background: var(--color-green);
      color: var(--color-white);
    }
  }

  &.is-purple {
    background: var(--color-purple-05);
    color: var(--color-primary);

    &.is-blur {
      background: var(--color-primary-02);
      color: var(--color-purple-06);
    }
  }

  &.is-disabled {
    background-color: var(--color-white-01);
    color: rgba(54, 53, 101, 0.5);
  }
}

.status-tag {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-transform: capitalize;
  color: var(--color-green);
  background-color: var(--color-primary-03);
  padding: 8px 10px;
  border-radius: 6px;

  &.is-light-green {
    background: var(--color-primary-03);
    color: var(--color-green);
  }

  &.is-yellow {
    background: #fffbe6;
    color: #faad14;
  }

  &.is-green {
    background: var(--color-green);
    color: var(--color-white);
  }

  &.is-red {
    background: #fff1f0;
    color: #f5222d;
  }

  &.is-purple {
    background: var(--color-primary-02);
    color: var(--color-purple-06);
  }

  &.is-gray {
    background: #fcfcfc;
    color: #c4c4c4;
  }
}

// COMMON STATUS
.common-status {
  font-weight: 500;
  font-size: 12px;
  color: #363565;
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 4px;
    border-radius: 50%;
  }

  &.is-green::before {
    background-color: #4a9d77;
  }

  &.disabled::before {
    background-color: #c4c4c4;
  }

  &.voucher {
    text-transform: capitalize;

    &::before {
      background-color: #c4c4c4;
    }

    &.active::before {
      background-color: #4a9d77;
    }

    &.disable::before {
      background-color: #c4c4c4;
    }

    &.redeemed::before {
      background-color: #363565;
    }

    &.invalid::before {
      background-color: #c4c4c4;
    }

    &.sold::before {
      background-color: #c4c4c4;
    }
  }

  &.promotion {
    &::before {
      background-color: #c4c4c4;
    }

    &.active::before {
      background-color: #4a9d77;
    }
  }
}

.booking-status {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #363565;
  text-transform: capitalize;
  gap: 4px;

  span {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    // background-color: #95de64;
  }
}

.booking_date {
  display: flex;
  align-items: center;

  .booking_date_value {
    font-size: 18px;
    color: #4A9D77;
  }
}

// MODAL
.modal-confirm {
  .ant-modal-confirm-btns {
    text-align: center;
  }

  button {
    outline: none;
  }
}

.ant-modal-content {
  position: relative;

  @include desktop {
    max-height: 80vh;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d9d9d9;
  }
}

.ant-modal.search-modal {
  top: 0;
  max-width: unset;
  width: 100% !important;
  height: 100vh;

  .ant-modal-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-color: var(--color-white-01);
    text-align: center;
  }

  input {
    display: flex;
    align-items: center;
    padding: 0 48px;
    gap: 12px;
    width: 100%;
    height: 48px;
    background: var(--color-white) var(--icon-search) left 15px center/15px auto no-repeat;
    border-radius: 6px;
  }

  .search-result-text {
    font-size: 14px;
    line-height: 140%;
    color: var(--color-primary);
    margin-top: 48px;
  }
}

.ant-modal.top-up-modal, .ant-modal.message-history-modal {
  top: 0;
  max-width: unset;

  .ant-modal-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-color: #FFF;;
    text-align: center;
    height: 90vh;
    max-height: unset;
  }

  .ant-modal-body {
    height: 100%;
  }

  @media only screen and (max-width: 768px) {
    .modal-title {
      margin-bottom: 24px;
      border: none;
    }
  }

  @media (max-width: 1180px) {
    width: 90% !important;

    .ant-modal-content {
      margin: 0 !important;
    }

    .ant-modal-body {
      padding-bottom: 0;
    }

    .modal-title {
      padding-bottom: 0;
    }
  }

  @media (min-width: 1181px) {
    width: 70% !important;
    max-width: 1148px;

    .ant-modal-content {
      padding-left: 48px;
      padding-right: 48px;
    }
  }
  
}

.form-row.form-title {
  @include mobile {
    font-size: 20px;
    text-align: center;
    border-bottom: 1px solid var(--color-purple-05);
    padding: 12px 50px;
    margin: -16px -12px 10px;
    justify-content: center;
    width: calc(100% + 24px);
  }
}

.modal-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: var(--color-primary);
  margin-bottom: 42px;

  @include mobile {
    font-size: 20px;
    text-align: center;
    border-bottom: 1px solid var(--color-purple-05);
    padding: 12px;
    margin: 0 -12px;
  }

  span {
    display: block;
    margin-bottom: -19px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
}

.modal-footer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  flex: 1;
  position: relative;

  .form-btn {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -60px);
  }
}

// FORM COMMON
.form-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include mobile {
    width: 75%;
  }

  &.is-big {
    font-size: 18px;
    font-weight: 600;
  }

  &.is-biggest {
    font-size: 20px;
  }

  &.is-center {
    line-height: 1.5;
  }
}

.form-sub-label {
  width: 100%;
  font-size: 16px;
  line-height: 140%;
  color: var(--color-primary);
  opacity: 0.7;
}

.form-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: var(--color-green);
  width: 100%;

  span {
    display: inline-block;
    cursor: pointer;
  }
}

.form-inner {
  display: flex;
  gap: 48px;

  @include tablet {
    display: block;
  }

  ;

  @include mobile {
    display: block;
    padding: 20px 0;
  }

  &.is-primary {
    .form-block:first-child {
      position: relative;

      @include mobile {
        border-bottom: 1px solid;
      }

      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        right: -24px;
        top: 0;
        border-left: 1px solid var(--color-purple);

        @include mobile {
          display: none;
        }
      }
    }

    .setting-service {
      display: flex;
      gap: 6px;
      margin-bottom: 15px;
      width: 100%;

      @include mobile {
        display: flex;
        gap: 24px;
      }
    }

    .price-service {
      @include mobile {
        width: 150px;
      }
    }

    .duration-service {
      @include mobile {
        width: 150px;
      }
    }

    .form-block {
      // display: flex;
      flex-direction: column;
    }
  }
}

.form-block {
  flex: 1;

  &.flex-col {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  @include mobile {
    margin-bottom: 24px;
    gap: 8px;
  }
}

.form-btn-add {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 40px;
  background: var(--color-primary);
  margin: 15px auto 35px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: var(--color-white);
  border: none;
  box-shadow: none;
  outline: none;
}

.form-row {
  display: flex;
  gap: 6px;
  margin-bottom: 15px;
  width: 100%;

  &.justify-content-center {
    justify-content: center;
  }

  @include mobile {
    // flex-wrap: wrap;
    margin-bottom: 0px;
  }

  &.is-wrap {
    flex-wrap: wrap;
  }

  &.items-end {
    align-items: flex-end;

    .ant-form-item .ant-form-item-explain-error {
      position: absolute;
    }

    .center-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #363565;
      margin: 0 5px 38px;
    }
  }
}

.form-price {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .price-value {
    font-weight: 600;
    font-size: 24px;
    flex: 1;
  }
}

.group_name .quantity-control {
  margin-right: 24px;
}

.quantity-control {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  gap: 4px;
  color: var(--color-primary);

  .quantity-value {
    min-width: 18px;
  }

  .control-btn {
    cursor: pointer;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    &.is-disabled {
      color: var(--color-purple-05);
    }
  }
}

.form-telephone {
  display: flex;
  align-items: flex-end;
}

.form-change-view {
  text-align: center;

  span {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    color: var(--color-green);
    cursor: pointer;
  }
}

.form-notifications-note {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 8px 8px 8px 44px;
  background: var(--color-primary-02) var(--icon-warning) center left 8px/24px auto no-repeat;
  border-radius: 8px;
  font-size: 12px;
  color: var(--color-purple-04);
  width: 100%;
  margin-top: 15px;
}

.form-booking-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 64px;
  background: #363565;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  color: var(--color-white);
  border: none;
  transition: all 0.3s ease;

  &:disabled {
    background: #949bda;
    cursor: no-drop;
  }
}

.form-submit-wrapper {
  display: flex;
  z-index: 8;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: sticky;
  margin-bottom: 0 !important;
  padding: 12px 100px;
  gap: 10px;
  bottom: 0;
  margin-top: 20px;

  &.is-big {
    max-width: 978px;
  }

  @include mobile {
    max-width: unset;
    // padding-right: 24px;
    left: 0;
    right: 0;
    padding: 0 10px;
  }

  &.non-bg {
    background: transparent;
  }

  &.fixed-bottom {
    position: fixed;
    bottom: 10px;
    // background: transparent;
    width: 100%;
    background: #FFFFFF;
    padding-top: 10x;
  }
}

.form-settings {
  &>.form-label {
    margin: 10px 0 20px;
  }

  .settings-list {
    display: flex;
    flex-direction: column;
    gap: 14px;

    li {
      display: flex;
      gap: 24px;
    }
  }
}

.form-note {
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-purple-06);
  padding: 10px 0;

  &.is-bottom {
    margin-top: -20px;
    margin-bottom: 10px;
  }

  &.is-small {
    font-size: 12px;
  }
}

.text-note {
  font-size: 12px;
  line-height: 140%;
  color: #7980bc;
  margin: 8px 0;
}

.form-warning {
  padding: 10px 40px;
  color: var(--color-green);
  background-color: var(--color-primary-03);
  border-radius: 6px;
  background-image: var(--icon-help-circle), var(--icon-right-green);
  background-position: left 8px center, right 8px center;
  background-size: 24px auto, 32px auto;
  background-repeat: no-repeat, no-repeat;
  font-style: italic;
  font-size: 14px;
  margin: 10px 0;
}

.form-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.form-tag {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  border-radius: 6px;
  padding: 8px 22px;

  &.is-green {
    color: var(--color-green);
    background-color: var(--color-primary-01);
  }

  &.is-red {
    color: var(--color-white);
    background-color: var(--color-red);
  }

  &.is-purple {
    color: var(--color-purple-02);
    background-color: rgba(51, 49, 167, 0.2);
  }

  &.is-yellow {
    color: var(--color-yellow);
    background-color: rgba(247, 201, 87, 0.2);
  }
}

.form-input-search {
  @include desktop {
    width: 100%;
  }

  input {
    display: none;
    width: 100%;
    height: 48px;
    align-items: center;
    background: var(--color-overlay) var(--icon-search-big) left 16px center/20px auto no-repeat;
    padding-left: 45px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-purple-blur);
    outline: none;
    border: none;

    &::placeholder {
      color: var(--color-purple);
    }

    @include desktop {
      display: flex;
    }
  }

  span {
    display: block;
    width: 30px;
    height: 30px;
    background: var(--icon-search) center center/15px auto no-repeat;
    cursor: pointer;

    @include desktop {
      display: none;
    }
  }
}

.form-select-wrapper {
  padding: 20px 0;
  width: 100%;

  .select-list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    li {
      display: flex;
      align-items: center;
      height: 64px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-transform: capitalize;
      color: var(--color-primary);
      background: var(--color-white-01);
      border-radius: 6px;
      padding: 12px;
    }

    .ant-checkbox-wrapper {
      color: var(--color-primary);
    }
  }
}

.form-setting {
  padding-top: 16px;

  .setting-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-primary);
    padding: 16px 0;
  }
}

.form-themes-list {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  margin-top: 15px;

  li {
    display: block;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
      transform: scale(1.1);
    }
  }
}

// PAGINATION
.table-pagination-custom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: var(--color-primary);
  position: relative;
  z-index: 11;
  padding-right: 104px;
  height: 58px;
  transform: translateY(-100%);
  gap: 18px;

  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border: none;
    box-shadow: none;
  }

  .ant-select-focused.ant-select:not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border: none;
    box-shadow: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    outline: none;
    min-width: 55px;
    box-shadow: none;
    height: auto;
    background: none;
  }
}

// COMPONENTS
.private-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 8px;

  @include mobile {
    width: 100%;
  }

  .common-btn {
    @include mobile {
      flex: none;
      width: 100%;
    }
  }

  .common-input-search {
    @include mobile {
      order: 2
    }

    input {
      @include mobile {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.filter {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-purple-blur);
  position: relative;
  width: 204px;
  height: 42px;
  background: var(--color-white);
  border-radius: 6px;
  padding: 0 16px;
  margin-left: auto;
  cursor: pointer;

  &::after {
    content: "";
    width: 10px;
    height: 13px;
    background: var(--icon-gt) center/contain no-repeat;
    position: absolute;
    right: 16px;
    top: 22px;
    transform: translateY(-50%) rotate(90deg);
  }

  .filter-label {
    width: 56px;
  }

  .filter-value {
    display: block;
    max-width: 100px;
    flex: 1;
    font-weight: 600;
    color: var(--color-purple-blur);
  }

  .filter-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    padding-left: 72px;
    background-color: var(--color-white);
    border-radius: 0 0 6px 6px;
    opacity: 0;
    transition: all 0.3s ease;
    pointer-events: none;

    li {
      padding: 5px 0;
    }
  }
}

.filter-active {
  .filter-list {
    opacity: 1;
    pointer-events: auto;
  }
}

.export-btn {
  width: 42px;
  height: 42px;
  background: var(--color-white) var(--icon-export) center/20px auto no-repeat;
  border-radius: 6px;
  border: none;
}

.filter-btn {
  width: 42px;
  height: 42px;
  background: var(--color-white) var(--icon-filter) center/20px auto no-repeat;
  border-radius: 6px;
  border: none;
}

// CARD
.profile-page {

  .cards-wrapper .cards-col,
  .cards-wrapper .common-card {
    @include tablet {
      min-width: 526px;
      max-width: 526px;
    }
  }

  &+.form-row.form-submit-wrapper {
    justify-content: flex-start;
  }
}

.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .cards-col {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    &.is-big {
      width: 978px;

      .common-card .card-body {
        max-width: unset;
      }
    }

    &.is-biggest {
      width: 1156px;

      .common-card .card-body {
        max-width: unset;
      }
    }

    @include desktop {
      max-width: 566px;
      flex: 1;
    }

    @include tablet {
      // width: 100%;
      order: 2;
      min-width: 100%;

      &:nth-child(2) {
        // order: 1;
      }
    }

    @include mobile {
      width: 100%;
      order: 2;

      &:nth-child(2) {
        // order: 1;
      }
    }
  }
}

.common-select {

  .ant-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number .ant-input-number-input,
  .ant-select-single .ant-select-selector,
  .ant-picker {
    min-width: 180px;
    height: 42px;
    background-color: var(--color-white);
    padding: 0 16px;

  }

  @include mobile {
    :where(.css-dev-only-do-not-override-1qkz0az).ant-select-single {
      width: 100%;
    }
  }

  .ant-select .ant-select-arrow {
    width: 6px;
    height: 12px;
    right: 18px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-picker .ant-picker-input>input {
    font-weight: 600;
  }
}

.d-flex.no-wrap {
  .common-input-search {
    flex: 1;

    &>input {
      width: 100%;
    }
  }
}

.common-input-search {
  // border: 1px solid #D9D9D9;
  border-radius: 10px;

  @include mobile {
    width: 100%;

    input {
      width: 100%;
    }
  }

  input {
    width: 218px;
    height: 42px;
    border: none;
    box-shadow: none;
    outline: none;
    border-radius: 10px;
    padding: 0 16px 0 48px;
    font-size: 16px;
    line-height: 140%;
    background: var(--color-white) var(--icon-search-big) left 16px center/20px auto no-repeat;

    &::placeholder {
      color: var(--color-purple);
    }
  }

  &.is-gray input {
    background-color: #f0f2f7;
    width: 100%;
  }
}

.common-btn-export {
  width: 160px;
  height: 42px;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 10px;
  padding: 0 16px 0 48px;
  font-size: 18px;
  line-height: 140%;
  background: var(--color-white) var(--icon-export-vertical) left 24px center/24px auto no-repeat;
  font-weight: 600;
  color: #363565;

  @include mobile {
    width: auto;
    padding: 0 21px;
    background-position: center;

    span {
      display: none;
    }
  }
}

.common-btn-import {
  width: 130px;
  height: 42px;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 10px;
  padding: 0 16px 0 48px;
  font-size: 18px;
  line-height: 140%;
  background: var(--color-white) var(--icon-import-vertical) left 24px center/24px auto no-repeat;
  font-weight: 600;
  color: #363565;

  @include mobile {
    width: auto;
    padding: 0 21px;
    background-position: center;

    span {
      display: none;
    }
  }
}

.common-card {
  width: 100%;
  background: var(--color-white);
  border-radius: 6px;
  padding: 20px 16px;

  @include tablet {
    width: 100%;
    height: auto;
  }

  &.w-t-50 {
    @include tablet {
      display: inline-block;
      width: calc(50% - 8px);
    }
  }

  .card-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: var(--color-primary);
    border-bottom: 1px solid var(--color-purple);
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin: 0 -20px 20px;
    padding: 0 20px 20px;

    @include desktop {
      padding: 0 0 20px;
      margin: 0 0 20px;
    }

    &.is-small {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    span {
      display: flex;
      font-style: italic;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: var(--color-purple-06);
      display: flex;
      align-items: center;
    }
  }

  .card-body {
    max-width: 526px;
    margin: 0 auto;
  }
}

// PICKER COLOR
.picker-color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: var(--color-white-01);
  border-radius: 6px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;

  .color {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    overflow: hidden;
  }

  .color-popover {
    position: absolute;
    z-index: 2;
    top: calc(100% + 10px);
    right: 10px;
  }

  .color-cover {
    position: fixed;
    top: auto;
    right: 0px;
    bottom: 100%;
    left: auto;
  }
}

// FORM CALENDAR
.form-calendar {
  @include mobile {
    min-width: 340px;
  }

  .calendar-row {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid rgba(195, 194, 224, 0.2);
    padding: 17px 0;
    position: relative;

    &:last-child {
      border-bottom: none;
    }
  }

  .calendar-label {
    display: flex;
    align-items: center;
    width: 105px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-primary);

    @include mobile {
      width: 70px;
    }
  }

  .pick-error {
    font-weight: 400;
    font-size: 16px;
    color: var(--color-purple);
    font-style: italic;
  }

  .calendar-pick {
    display: flex;
    align-items: center;
  }

  .list-pick {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: -5px 0;
  }

  .pick-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-primary);
  }

  .btn-delete {
    display: block;
    width: 30px;
    height: 30px;
    background: var(--icon-delete) center/13px auto no-repeat;
    cursor: pointer;
  }

  .calendar-add {
    display: block;
    width: 20px;
    height: 20px;
    background: var(--icon-plus) center/contain no-repeat;
    position: absolute;
    right: 12px;
    top: 18px;
    cursor: pointer;
  }

  // CUSTOM ANT CHECKBOX
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    color: var(--color-primary);
  }

  .ant-checkbox {
    transform: translateY(-4px);

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      background: rgba(195, 194, 224, 0.2);
      border: none;

      &::after {
        width: 7px;
        height: 12px;
        border-top-width: 3px;
        left: 7px;
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(54, 53, 101, 0.8);
  }

  .ant-input,
  .ant-picker {
    width: 90px;
    height: 34px;
    min-width: unset;
    font-size: 16px;
    padding: 0 10px;
    justify-content: center;
    text-align: center;

    .ant-picker-input>input {
      font-size: 16px;
      font-weight: 500;
    }

    .ant-picker-suffix {
      width: 24px;
      height: 24px;
    }
  }

  .ant-picker {
    padding-right: 0;
    // padding-left: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
}

.ant-picker-dropdown .ant-picker-ranges {
  gap: 15px;
}

// VIEWS
.common-list-views {
  display: flex;
  background: var(--color-white);
  border-radius: 6px;

  li {
    width: 42px;
    height: 42px;
    background: center/contain no-repeat;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .grid-view {
    background-image: var(--icon-view-grid);

    &:hover,
    &.is-active {
      background-image: var(--icon-view-grid-active);
    }
  }

  .list-view {
    background-image: var(--icon-view-list);

    &:hover,
    &.is-active {
      background-image: var(--icon-view-list-active);
    }
  }

  .calendar-view {
    background-image: var(--icon-view-calendar);

    &:hover,
    &.is-active {
      background-image: var(--icon-view-calendar-active);
    }
  }
}

// MORE SETTINGS
.more-settings {
  display: none;
  flex-direction: column;
  gap: 4px;
  width: 210px;
  background: var(--color-white);
  box-shadow: 0px 4px 24px rgba(225, 225, 239, 0.6);
  border-radius: 6px;
  position: absolute;
  padding: 8px;
  transform: translate(33px, 0);

  &.is-show {
    display: flex;
  }

  li {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    font-size: 14px;
    line-height: 140%;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    padding-left: 36px;
    background: left 8px center/20px auto no-repeat;

    &:hover {
      background-color: var(--color-white-01);
    }
  }

  .is-edit {
    background-image: var(--icon-settings-edit);
  }

  .is-split {
    background-image: var(--icon-settings-split);
  }

  .is-double {
    background-image: var(--icon-settings-copy);
    position: relative;
    margin-bottom: 4px;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      border-bottom: 2px solid var(--color-white-01);
      position: absolute;
      left: 0;
      bottom: -5px;
    }
  }

  .is-cancel {
    background-image: var(--icon-settings-close);
    color: var(--color-red);
  }

  .is-disabled {
    background-image: var(--icon-settings-user);
    color: var(--color-purple-06);
  }
}

.fullName-uses {
  @include mobile {
    width: 150px;
  }
}

.btn-noti.is-disabled {
  pointer-events: none;
}

// BOOKINGS GRID BLOCK
.booking-grid-block {
  background: var(--color-white);
  border-radius: 12px;
  margin: 0 auto;
  color: var(--color-primary);

  &.is-full {
    width: 100%;

    // max-width: 1012px;
    .block-heading {
      height: 42px;
    }
  }

  @media only screen and (min-width: 1025px) {}

  .block-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-purple);
    height: 90px;
    padding: 0 24px;

    &.no-border {
      border: none;
    }

    @include mobile {
      height: 64px;
      justify-content: flex-start;
      padding: 0 35px;
    }
  }

  .block-body {
    overflow: auto;
    max-height: calc(100vh - 262px);
    margin-bottom: -24px;
  }

  .heading-date {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 8;

    .btn-prev,
    .btn-next {
      width: 24px;
      height: 24px;
      background: center/8px auto no-repeat;
      border: none;
    }

    .btn-prev {
      background-image: var(--icon-pagination-left);
    }

    .btn-next {
      background-image: var(--icon-pagination-right);
    }

    p {
      width: 196px;
    }
  }

  .heading-total {
    font-size: 16px;

    span {
      font-weight: 600;
    }
  }

  .list-cards {
    margin: 0 auto;
    overflow: auto;

    @include mobile {
      padding: 0;
    }

    .gutter-box {
      padding: 8px 0;
    }
  }
}

.quick-booking-btn {
  margin-left: auto;

  svg {
    display: none;
  }

  @include mobile {
    // background-color: #fff;
    padding: 12px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 3;
    width: 56px;
    height: 56px;

    .common-btn {
      width: 56px;
      height: 56px;
      min-width: 56px;
      border-radius: 50%;

      .icon-calender-mobile {
        display: block !important;
      }

      svg {
        display: block;
      }

      p {
        display: none;
      }
    }
  }
}

// COMMON BOOKING CARD
.common-booking-card {
  background: var(--color-white);
  box-shadow: 0 4px 12px rgba(44, 43, 61, 0.15);
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  height: 100%;
  padding: 16px 24px;

  @include mobile {
    padding: 12px;
  }

  &::after {
    content: "";
    display: block;
    width: 6px;
    height: 100%;
    border-radius: 6px 0px 0px 6px;
    position: absolute;
    left: -2px;
    top: 0;
    background: var(--color-green);
  }

  &.completed::after {
    background: var(--color-green);
  }

  &.no_show::after {
    background: var(--color-purple);
  }

  &.draft::after {
    background: var(--color-gray);
  }

  &.cancelled::after {
    background: var(--color-red-02);
  }

  .common-action {
    transform: none;
    margin-top: 0;
    margin-left: 12px;
  }

  .item-heading {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    gap: 12px;
    @include mobile {
      gap: 8px;
    }
  }

  .heading-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    background: linear-gradient(315deg, #6587ff 0%, #c883ff 100%);
    font-size: 28px;
    color: #fff;
    text-align: center;
    font-weight: 600;
  }

  .heading-detail {
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 12px;
    line-height: 140%;
    color: var(--color-primary);
    flex: 1;

    span {
      font-weight: 600;
    }
  }

  .heading-title {
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    line-height: 1.4;

    @include mobile {
      font-size: 14px;
    }

    &.text-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 95%;
    }
  }

  .services-list {
    padding: 16px 0;
    border-top: 1px solid var(--color-white-01);
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
    transition: all 0.3s ease;

    &>li {
      font-size: 14px;
      line-height: 140%;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .service-left {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 12px;
      flex: 1;

      span {
        font-weight: 600;
      }
    }

    .service-type {
      display: block;
      width: 12px;
      height: 12px;
      background-color: #5056e9;
      border-radius: 50%;
    }

    .service-name {
      font-weight: 600;
      font-size: 14px;
      white-space: nowrap;
      // overflow: hidden;
      text-overflow: ellipsis;
      width: 100px;
    }

    .service-time {
      width: 200px;
      @include mobile {
        width: 100%;
      }
    }

    .service-quantity {
      font-size: 16px;
    }

    .service-price {
      min-width: 40px;
      display: block;
      margin-left: 10px;
      font-weight: 600;
      font-size: 16px;
      text-align: right;
    }

    &.is-close {
      max-height: 60px;
      overflow: hidden;

      &+.accordion-control .control-btn::after {
        transform: rotate(90deg);
      }
    }

    &.is-open {
      max-height: unset;
    }
  }

  .accordion-control {
    text-align: center;
    margin-bottom: 25px;

    .control-btn {
      display: inline-flex;
      align-items: center;
      font-weight: 600;
      text-align: center;
      text-transform: capitalize;
      color: var(--color-primary);
      cursor: pointer;
      gap: 10px;

      &::after {
        content: "";
        display: block;
        width: 5px;
        height: 10px;
        background: var(--icon-gt) center / contain no-repeat;
        transform: rotate(-90deg);
      }

      span {
        color: var(--color-primary);
      }
    }
  }

  .services-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--color-white-01);
    padding-top: 15px;

    .total-label {
      font-weight: 500;
      font-size: 16px;
    }

    .total-price {
      font-weight: 600;
      font-size: 24px;
      color: var(--color-green);
    }
  }

  .btn-refund-print {
    text-align: center;

    .btn-refund {
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
      padding: 10px 43px;
      color: #4a9d77;
      border: 1px solid #4a9d77;
      border-radius: 6px;
      margin-right: 6px;
    }

    .btn-print {
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
      padding: 10px 23px;
      color: #ffffff;
      border: 1px solid #4a9d77;
      border-radius: 6px;
      background-color: #4a9d77;
      margin-left: 6px;
    }
  }

  .card-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding-top: 16px;
    text-align: center;

    @include mobile {
      display: flex;

      .common-btn,
      .common-btn.ant-btn-default {
        flex: 0;
      }
    }
  }
}

.invoice-service-details-card {
  background: var(--color-white);
  border-radius: 6px;
  position: relative;

  .services-table {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-primary);

    tr.tr-service {
      th {
        text-transform: uppercase;
      }
    }

    .text-left {
      text-align: left;
    }

    .text-right {
      text-align: right;
    }

    .text-center {
      text-align: center;
    }

    .text-price {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      text-align: right;
      color: #363565;
    }

    .text-total {
      font-weight: 600;
    }

    .tr-total {
      .text-price {
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        text-align: right;
        text-transform: uppercase;
        color: #4a9d77;
      }
    }

    .tr-payment-method {
      .text {
        text-transform: uppercase;
        word-wrap: nowrap;
      }
    }

    .service_name {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #363565;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150px;
      text-transform: capitalize;
    }

    .service_by {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #363565;
    }

    th {
      text-align: left;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }

    td {
      padding-top: 11px;
      padding-bottom: 11px;

      &.col-id {
        padding-right: 8px;
      }
    }

    .space {
      padding-top: 11px;
      padding-bottom: 11px;
    }

    .half-space {
      padding-top: 5.5px;
      padding-bottom: 5.5px;
    }

    .divider {
      padding: 0;
      height: 2px;
      width: 100%;
      background: var(--color-overlay);
      border-radius: 6px;
    }
  }
}

.reviews-list {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &>li {
    display: flex;
    align-items: flex-start;
    padding: 24px;
    position: relative;
    background: var(--color-overlay);
    border-radius: 6px;

    &::after {
      content: "";
      display: block;
      width: 6px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: var(--color-purple);
      border-radius: 6px 0px 0px 6px;
    }
  }

  .review-avatar {
    width: 61px;
    height: 61px;
    border-radius: 50%;
    overflow: hidden;
  }

  .review-detail {
    flex: 1;
    padding-left: 24px;
    font-weight: 500;
  }

  .list-items {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &>li {
      color: var(--color-primary);
      font-weight: 500;
    }
  }

  .item-time {
    font-weight: 500;
    font-size: 14px;
    color: var(--color-purple);
  }
}

// APPOINTMENT
.appointment-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4px 0 35px;
  counter-reset: appointment-counter;

  li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-primary);
    border-bottom: 1px solid var(--color-white-01);
    padding: 16px 25px 16px 64px;
    counter-increment: appointment-counter;
    position: relative;

    &:last-child {
      border-bottom: 0;
    }

    &::before {
      content: counter(appointment-counter);
      position: absolute;
      top: 15px;
      left: 16px;
    }

    p {
      line-height: 24px;
    }
  }

  .appointment-staff {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    gap: 5px;
  }

  .staff-image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
  }

  &.is-active {
    li:first-child {
      background: var(--color-white-01);
      border-radius: 6px;
    }
  }
}

.form-new-member {
  font-weight: 600;

  .ant-checkbox-wrapper {
    color: var(--color-primary);
  }

  &.ant-form {
    gap: 16px;
  }

  .ant-checkbox+span {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .ant-image {
    width: 24px;
    height: 24px;
  }

  .member-price {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 61px;
    height: 34px;
    background: var(--color-purple-05);
    border-radius: 6px;
    font-size: 16px;
    line-height: 140%;
    color: var(--color-primary);
  }
}

// notification
.notification-block {
  position: absolute;
  width: 440px;
  background-color: var(--color-white);
  filter: drop-shadow(0px 4px 24px rgba(225, 225, 239, 0.6));
  border-radius: 6px;
  top: calc(100% + 8px);
  right: 0;
  z-index: 101;
  height: 500px;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c3c2e0;
  }

  @include mobile {
    right: 12px;
    width: calc(100% - 24px);
    top: 90px;
  }

  .notification-remove-btn {
    display: block;
    width: 24px;
    height: 24px;
    background: var(--icon-remove-red) center/contain no-repeat;
    position: absolute;
    left: 16px;
    top: 12px;
  }

  .notification-title {
    text-align: center;
    padding: 12px 16px;
    font-weight: 600;
    font-size: 16px;
    color: var(--color-primary);
  }

  .notification-list {
    li {
      display: flex;
      gap: 16px;
      padding: 16px;
      border-bottom: 1px solid var(--color-white-02);
      position: relative;
      transition: all 0.3s ease;
      cursor: pointer;

      &:last-child {
        border-bottom: 1px solid var(--color-purple);
      }

      &:hover {
        opacity: 0.7;
      }

      // &::before {
      //   content: "";
      //   display: block;
      //   width: 8px;
      //   height: 8px;
      //   background: var(--color-blue-02);
      //   border: 1px solid var(--color-blue-01);
      //   position: absolute;
      //   left: 8px;
      //   top: 8px;
      //   border-radius: 50%;
      // }
      .no_icon {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        left: 8px;
        top: 8px;
        border-radius: 50%;
      }

      .icon {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        background: var(--color-blue-02);
        border: 1px solid var(--color-blue-01);
        position: absolute;
        left: 8px;
        top: 8px;
        border-radius: 50%;
      }
    }
  }

  .notification-text {
    color: var(--color-primary);

    span {
      font-weight: 600;
    }
  }

  .notification-time {
    color: var(--color-purple-07);
    margin-top: 28px;
  }

  .notification-avatar {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;

    &+div {
      flex: 1;
    }
  }

  .notification-footer {
    text-align: center;
    padding: 10px 16px 14px;

    span {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      color: var(--color-primary);
      cursor: pointer;
    }
  }

  &.is-all {
    position: static;
    width: 566px;
    padding: 0 20px;

    @include mobile {
      width: 100%;
    }

    .notification-title {
      text-align: left;
      border-bottom: 1px solid var(--color-white-01);
      padding: 20px 0;
      margin-bottom: 20px;
    }

    .notification-remove-btn {
      left: auto;
      right: 20px;
      top: 20px;
    }

    .notification-list {
      li {
        border-bottom: none;
      }
    }
  }
}

// PIE
@property --p {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.pie-wrapper {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto;

  &.is-red {
    .pie {
      --c: var(--color-red);
    }

    &::after {
      border-color: var(--color-red);
    }

    .pie-content {
      color: var(--color-red);
      background-color: rgba(233, 80, 96, 0.1);
    }
  }

  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    border-radius: 50%;
    border: 20px solid var(--color-green);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.5;
  }

  .pie-content {
    width: 133px;
    height: 133px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-primary-03);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-green);

    span {
      font-weight: 500;
      font-size: 14px;
    }

    p {
      font-weight: 600;
      font-size: 28px;
      line-height: 100%;
      text-align: center;
    }
  }
}

.pie {
  --p: 90;
  --b: 20px;
  --c: var(--color-green);
  --w: 300px;
  position: relative;
  z-index: 11;
  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;
  font-size: 25px;
  font-weight: 600;
  font-family: sans-serif;
}

.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.pie:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), var(--color-black) calc(100% - var(--b)));
  mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), var(--color-black) calc(100% - var(--b)));
  // background-size: 0 0, auto;
}

.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg)) translateY(calc(50% - var(--w) / 2));
}

.animate {
  animation: p 1s 0.5s both;
}

.no-round:after {
  content: none;
}

@keyframes p {
  from {
    --p: 0;
  }
}

.common-growth {
  display: block;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-green);
  padding-left: 20px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--color-green);
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.is-reduce {
    color: var(--color-red);

    &::before {
      border-top: 5px solid var(--color-red);
      border-bottom: 0;
    }
  }
}

.common-avatar {
  background: url("/assets/images/auth/avatar_bg.png") center/contain no-repeat;
}

.modal-delete-confirm {
  text-align: center;

  // .modal-icon {}

  .modal-title {
    margin: 30px 0 5px;
    font-weight: 600;
    font-size: 20px;
  }

  .modal-text {
    font-size: 16px;
    margin-bottom: 40px;
  }
}

.private-wrapper.is-show-sidebar {

  .block-fixed,
  .quick-booking-btn {
    @include mobile {
      display: none;
    }
  }
}

.popover-multiple-booking {
  .ant-popover-content .ant-popover-inner {
    max-height: 35vh;
  }
}

.time-picker-custom {
  .ant-picker-ok {
    opacity: 0;
    height: 1px;
  }
}

.text-error {
  color: var(--color-red);
  margin-top: -16px;
}

.text-error-cus {
  color: var(--color-red);
  text-align: center;
  margin-top: 16px;
}

// Css
.StoreName_service {
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  color: #1B9AAA;
  text-decoration: underline;
}

.fullname_user {
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  color: #1B9AAA;
  text-decoration: underline;
}