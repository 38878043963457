@charset "utf-8";

/* PRINTING
 * ----------------------------------------------- */

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  html,
  body {
    margin: 0;
  }

  @page {
    margin: 0;
  }

  @page :left {
    margin: 0;
  }

  @page :right {
    margin: 0;
  }

  @page :first {
    margin-top: 0;
  }
}

/* GENERAL
 * ----------------------------------------------- */

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto;
  }
}

body {
  color: var(--font-color);
  font-family: var(--font-base);
  font-weight: 400;
  line-height: 1.4;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  transition: opacity 0.3s ease;
  overflow-y: scroll;
}

p {
  font-family: var(--font-base);
}

a {
  color: var(--font-color);
  text-decoration: none;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
}

p {
  margin-bottom: 0;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.hidden {
  display: none;
}

.container {
  overflow: hidden;
  background-color: var(--color-white-01);
  @include tablet {
    font-size: 10px;
  }
}

/* RESPONSIVE
 * ----------------------------------------------- */

body {
  font-size: 14px;
}

.container {
  min-width: 320px;
  min-height: 100vh;
  @include desktop {
    padding-left: 148px;
  }
}

.wrapper {
  padding: 0 25px;
  margin-left: auto;
  margin-right: auto;
}

input,
button {
  font-family: var(--font-base);
}

button {
  padding: 0;
  border: none;
  background: none;
}
