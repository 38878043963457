@import "assets/sass/abstracts/mixin";

.header_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 1652px;
  padding: 24px 20px 24px 50%;
  margin: 0 auto;
  @include tablet {
    padding: 20px 58px 24px 20%;
  }
  @include mobile {
    padding: 34px 12px 34px 20%;
    position: relative;
  }
}

.header_search {
  max-width: 664px;
  flex: 1;
  margin-right: 50px;
  input {
    display: flex;
    width: 100%;
    height: 48px;
    align-items: center;
    background: var(--color-white) var(--icon-search) left 15px center/15px auto no-repeat;
    padding-left: 45px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-purple-blur);
    outline: none;
    border: none;
  }
}

.header_user {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
}

.header_notification {
  display: block;
  margin: 0px 30px 10px 24px;
  position: relative;
  @include mobile {
    position: static;
  }
  &.active .notification_icon {
    background-color: var(--color-white);
  }
  .notification_icon {
    display: block;
    width: 32px;
    height: 32px;
    background: var(--icon-notification) center/20px auto no-repeat;
    border-radius: 6px;
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
      background-color: var(--color-white);
    }
  }
  .notification_icon_active {
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background-color: var(--color-red);
      border-radius: 50%;
      position: absolute;
      right: 8px;
      top: 6px;
    }
  }
  @include mobile {
    margin-right: 26px;
  }
}

.active .notification_icon {
  background-color: var(--color-white);
}

.header_message {
  margin: 5px 30px 0 24px;
  .message_icon {
    display: block;
    width: 21px;
    height: 21px;
    background: var(--icon-email) center/contain no-repeat;
  }
  @include mobile {
    margin-right: 26px;
  }
}