@import "../../../assets/sass/abstracts/mixin";

.form_extra {
    &_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 21px;
        &_title {
            font-weight: 500;
            font-size: 14px;
        }
    }

}

.titleContainer {
    display: flex;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1B9AAA;
    text-decoration: underline;



    @include mobile {
        display: grid !important;

        .optionsNumber {
            margin-left: 0;
        }
    }
}

.optionsNumber {
    color: #595959;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;

    @include mobile {
        margin-left: 0;
    }
}