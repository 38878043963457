.detail_table {
  width: 100%;
  font-size: 16px;
  color: var(--color-primary);
  border-top: 1px solid #f0f2f7;
  margin-top: 16px;
  tr {
    border-bottom: 1px solid #f0f2f7;
  }
  th {
    font-weight: 400;
    text-align: left;
    padding: 10px 0;
  }

  td {
    font-weight: 600;
    padding: 10px 0;
    text-align: right;
  }
}

.list_images {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  padding: 18px 0;
}
