@import "assets/sass/abstracts/mixin";

.auth-layout {
  // height: 100%;
  position: fixed;
  inset: 0;
  z-index: 1;
  background: linear-gradient(90deg, var(--color-primary) 0%, var(--color-purple-08) 100%);
  overflow: hidden;
  @include tablet {
    background-color: var(--color-white);
  }
  @include mobile {
    min-height: 100vh;
    height: auto;
  }

  &::after {
    @include desktop {
      content: "";
      display: block;
      height: 94vh;
      background: url("/assets/images/auth/auth_bg.png") center/contain no-repeat;
      position: absolute;
      left: 0%;
      right: 50%;
      bottom: 0;
    }
  }
  input {
    max-width: 502px;
    margin-left: auto;
    margin-right: auto;
  }
  .btn-submit {
    margin-left: auto;
    margin-right: auto;
  }

  .layout-right {
    background-color: var(--color-white);
    height: 100%;
    margin-left: auto;
    @include desktop {
      width: 46.35%;
    }
  }

  .auth-content {
    margin-right: auto;
    height: 100%;
    position: relative;
    background-color: var(--color-white-01);
    min-height: 800px;

    @include desktop {
      max-width: 100%;
    }
  }

  .content-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 634px;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    height: 100%;
    padding: 22px 24px 0;
    background-color: var(--color-white-01);
    @include mobile {
      min-height: 100vh;
      padding-top: 50px;
      padding-bottom: 200px;
    }
    .auth-logo {
      // position: absolute;
      // left: 50%;
      // transform: translateX(-50%);
      // bottom: 60px;
    }
  }

  .btn-back {
    display: block;
    width: 36px;
    height: 36px;
    cursor: pointer;
    background: var(--icon-gt) center/10px auto no-repeat;
    transform: rotate(180deg);
    position: absolute;
    left: 24px;
    top: 24px;
  }

  .auth-avatar {
    width: 132px;
    height: 132px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 36px;
  }

  .auth-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 34px;
    margin-bottom: 14px;
  }

  .auth-text {
    font-size: 20px;
    margin-bottom: 50px;
    line-height: 1.7;
    white-space: pre-line;
  }

  .form-row {
    display: block;
    margin-top: 24px;
    margin-bottom: 24px;
    &.dl-flex {
      display: flex;
    }
  }

  .auth-forgot {
    max-width: 502px;
    margin: 16px auto;
    text-align: right;
    span {
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .forgot-logo {
    margin-bottom: 50px;
  }

  input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    border-radius: 6px;
    border: 1px solid transparent;
    color: var(--color-primary);
    background-color: var(--color-white);
    outline: none;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 500;
    font-size: 16px;

    &::placeholder {
      color: var(--color-purple);
    }

    &:disabled {
      opacity: 1;
    }
  }

  .btn-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 502px;
    max-width: 100%;
    height: 64px;
    border-radius: 6px;
    border: 1px solid var(--color-primary);
    color: var(--color-white);
    background-color: var(--color-primary);
    outline: none;
    font-weight: 600;
    font-size: 16px;
    // &:disabled,
    // &[disabled] {
    //   pointer-events: none;
    //   background-color: var(--color-purple-06);
    //   border-color: var(--color-purple-06);
    // }

    @media only screen and (max-width: 1200px) {
      width: 400px;
    }

    @include mobile {
      width: 100%;
    }
  }
}
