@import "assets/sass/abstracts/mixin";

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 52px;
  margin-left: 94px;
  padding-right: 20%;

  @include desktop {
    margin-left: 0;
    padding-right: 50%;
  }

  @include mobile {
    padding-right: 0;
    margin-left: 0;
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 1250px) {
    margin-bottom: 16px;
  }

  li {
    font-size: 36px;
    line-height: 42px;
    color: var(--color-primary);
    position: relative;
    margin-right: 17px;
    padding-right: 27px;
    text-transform: capitalize;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;

    /* Standard syntax */
    @include sm {
      font-size: 24px;
    }

    @include xl {
      font-size: 36px;
    }

    &:last-child::after {
      display: none;
    }

    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 16px;
      background: var(--icon-gt) center/contain no-repeat;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child {
      font-weight: 600;
    }
  }

  li:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 1250px) {
    li {
      font-size: 20px;
    }
  }
}