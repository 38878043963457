@import "assets/sass/abstracts/mixin";

.customers_detail_page {
  padding-top: 50px;
}


.general_footer {
  display: flex;
  width: 100%;
  gap: 10px;
  padding: 45px 0 45px 8%;

  @include mobile {
    padding: 0;
  }

  @include tablet {
    justify-content: center;
    padding-left: 0;
  }
}