@import "assets/sass/abstracts/mixin";

.aside {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 102;
  transform: translateX(-100%);
  transition: all 0.3s ease;
  max-height: 100vh;
  overflow-y: auto;
  margin-right: -150px;
  pointer-events: none;
  background-color: var(--color-white);

  &:hover {
    pointer-events: auto;
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #d9d9d9;
  }

  @include desktop {
    transform: none !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.aside_inner {
  min-height: 100%;
  padding: 40px 12px;
  padding-top: 0;
  background-color: var(--color-white);
  pointer-events: auto;
}

.aside_show {
  transform: translateX(0);

  &+.aside_overlay {
    opacity: 1;
    visibility: visible;
  }
}

.aside_overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: all 0.3s ease;
  visibility: hidden;
}

.aside_toggle {
  display: inline-block;
  width: 36px;
  height: 36px;
  background: var(--icon-menu) center/contain no-repeat;
  border: none;
  position: absolute;

  @include desktop {
    display: none;
  }

  @include mobile {
    left: 12px;
    top: 40px;
  }
}

.aside_close {
  display: block;
  width: 24px;
  height: 24px;
  // margin: 7px auto 88px;
  margin: 20px auto 88px;
  background: var(--icon-menu-close) center/contain no-repeat;
  border: none;

  @include desktop {
    display: none;
  }
}

.aside_logo {
  display: none;
  text-align: center;
  margin-bottom: 88px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding-top: 40px;
  background-color: var(--color-white);

  @include desktop {
    display: block;
  }
}
.list_navbar {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
}

.navbar_item {
  position: relative;

  &:nth-child(3n + 3) {
    position: relative;
    margin-bottom: 10px;

    &::after {
      content: "";
      display: block;
      width: calc(100% - 24px);
      height: 1px;
      border-top: 1px solid var(--color-purple-07);
      position: absolute;
      left: 12px;
      bottom: -10px;
    }
  }

  a>* {
    display: block;
  }

  &>span,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 72px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 6px;
    color: var(--color-purple-07);
    transition: all 0.3s ease;
    cursor: pointer;

    path {
      transition: all 0.3s ease;
    }

    &:hover {
      background: var(--color-overlay);
      color: var(--color-primary);
      opacity: 1;

      path {
        fill: var(--color-primary);
      }
    }

    path {
      fill: var(--color-purple-07);
    }
  }

  img {
    margin: 0 auto;
  }

  span {
    line-height: 1.7;
  }

  .navbar_link_active {
    path {
      fill: var(--color-primary);
    }

    color: var(--color-primary);
  }

  .nav_item_parent_active {
    color: var(--color-primary);

    path {
      fill: var(--color-primary);
    }
  }
}

.navbar_sub_menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // width: 146px;
  width: 130px;
  top: 50%;
  padding: 10px;
  background: var(--color-white);
  box-shadow: 6px 4px 48px rgba(44, 43, 61, 0.15);
  border-radius: 0px 6px 6px 0px;
}

.nav_item_active {
  &+.navbar_sub_menu {
    opacity: 1;
    visibility: visible;
  }
}