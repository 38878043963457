:root {
  --color-primary: #363565;
  --color-green: #4a9d77;
  --color-primary-01: #e2f4ec;
  --color-primary-02: #f8f8ff;
  --color-primary-03: #f4fffa;
  --color-red: #e95060;
  --color-red-01: #ffeff1;
  --color-red-02: #F5222D;
  --color-white: #fff;
  --color-white-01: #f0f2f7;
  --color-white-02: #e4e8ee;
  --color-black: #000;
  --color-purple: #c3c2e0;
  --color-purple-01: #ccd1fe;
  --color-purple-02: #3331a7;
  --color-purple-03: #e7e9f3;
  --color-purple-04: #60669f;
  --color-purple-05: #e2e5ff;
  --color-purple-06: #949bda;
  --color-purple-07: #b1b8f7;
  --color-purple-08: #dddcff;
  --color-purple-blur: rgba(54, 53, 101, 0.8);
  --color-yellow: #f7c957;
  --color-yellow-01: #ffc107;
  --color-yellow-02: #fadb14;
  --color-blue-01: #4299e1;
  --color-blue-02: #90cdf4;
  --color-overlay: rgba(195, 194, 224, 0.2);
  --color-gray: #c4c4c4;

}
