@import "assets/sass/abstracts/mixin";

.customers_detail_page {
  padding-top: 50px;
}

.statistics_wrapper {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-purple-blur);
  li:not(:last-child) {
    margin-bottom: 15px;
  }
  span {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: var(--color-primary);
  }
}

.statistics_left {
  border-right: 1px solid var(--color-purple);
  width: 30.5%;
}

.statistics_right {
  flex: 1;
  padding-left: 20px;
}

.notifications_wrapper {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-primary);
  li:not(:last-child) {
    margin-bottom: 18px;
  }
}

.notifications_label {
  display: flex;
  gap: 20px;
}

.notifications_note {
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-purple);
  margin-top: 12px;
}

.general_footer {
  display: flex;
  width: 100%;
  gap: 10px;
  padding: 45px 0 45px 8%;
  @include tablet {
    justify-content: center;
    padding-left: 0;
  }
  @include mobile {
    padding: 25px 0;
  }
}

.customers_list_service {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-primary);
  margin-bottom: 80px;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

// CUSTOMERS ACTIVITY
.activity_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 26px 0;
  & > li {
    display: flex;
    align-items: flex-start;
    background: var(--color-overlay);
    border-radius: 6px;
    position: relative;
    padding: 24px;
    overflow: hidden;
    &::after {
      content: "";
      display: block;
      width: 3px;
      height: 100%;
      background: var(--color-purple);
      border-radius: 6px 0px 0px 6px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_user {
    width: 61px;
    height: 61px;
    border-radius: 50%;
    overflow: hidden;
  }

  .item_detail {
    display: flex;
    flex-direction: column;
    gap: 25px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-primary);
    padding-left: 24px;
    padding-right: 50px;
    flex: 1;
  }

  .detail_time {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-purple);
    &.is_green {
      color: var(--color-green);
      text-decoration: underline;
    }
  }
}

// CUSTOMERS APPOINTMENTS
.appointments_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.reviews_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  & > li {
    display: flex;
    align-items: flex-start;
    padding: 24px;
    position: relative;
    background: var(--color-overlay);
    border-radius: 6px;
    &::after {
      content: "";
      display: block;
      width: 6px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: var(--color-purple);
      border-radius: 6px 0px 0px 6px;
    }
  }
  .review_avatar {
    width: 61px;
    height: 61px;
    border-radius: 50%;
    overflow: hidden;
  }

  .review_detail {
    flex: 1;
    padding-left: 24px;
    font-weight: 500;
  }

  .list_items {
    display: flex;
    flex-direction: column;
    gap: 14px;
    & > li {
      color: var(--color-primary);
      font-weight: 500;
    }
  }

  .item_time {
    font-weight: 500;
    font-size: 14px;
    color: var(--color-purple);
  }

  .list_images {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    padding: 18px 0;
    li {
      border-radius: 6px;
      overflow: hidden;
    }
  }
}
