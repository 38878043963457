.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 502px;
  max-width: 100%;
  height: 64px;
  border-radius: 6px;
  border: 1px solid var(--color-primary);
  color: var(--color-white);
  background-color: var(--color-primary);
  outline: none;
  font-weight: 600;
  font-size: 16px;

  &:disabled,
  &[disabled] {
    pointer-events: none;
    background-color: var(--color-purple-06);
    border-color: var(--color-purple-06);
  }
}

.label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}