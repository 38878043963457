// @import "//assets/sass/abstracts/mixin";
@import "../../../assets/sass/abstracts/mixin";

.form_footer {
  // max-width: 566px;
  position: fixed;
  padding-top: 16px;
  border-radius: 6px;
  // bottom: 20px;
  // @include mobile {
  //   padding: 12px 25px 12px 0;
  // }
}

.form_footer_shadow {
  height: 80px;
  width: 100%;
}

.profile_inner {
  display: flex;
  width: 100%;
  gap: 40px;

  &>div {
    flex: 1;
  }

  @include mobile {
    display: block;
  }
}

.profile_heading {
  margin-bottom: 40px;
}

.heading_title {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: var(--color-primary);
  margin-bottom: 16px;
}

.heading_detail {
  display: flex;
  align-items: center;
  gap: 24px;

  @include mobile {
    display: block;
  }
}

.detail_avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
}

.detail_control {
  display: flex;
  gap: 10px;
}

.periods_list {
  &>li {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    gap: 24px;
    border-bottom: 1px solid #f0f2f7;

    &:last-child {
      border-bottom: none;
    }
  }
}

.item_content {
  color: #7980bc;
  max-width: calc(100% - 90px);

  p:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.item_label {
  font-weight: 600;
  color: var(--color-primary);
  margin-bottom: 8px;
}

//  Payment Method
.payment_method_container {
  max-width: 100%;
  display: grid;
  justify-content: center;

  .payment_method_table {
    display: grid;
    gap: 24px;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid #8C8C8C;
    width: 452px;

    @include mobile {
      width: 100%;
    }

    .payment_method_title {
      font-size: 20px;
      font-weight: 600;
      line-height: 140%;
      margin-bottom: 8px;
    }

    .payment_method_content {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: #8C8C8C;
    }

    .payment_method_item_container {
      display: grid;

      .payment_method_item {
        display: grid;
        gap: 24px;
        align-items: center;
        margin: 8px 0;
        grid-template-columns: 1fr 20fr 1fr;

        .payment_method_item_content {
          display: flex;
          gap: 12px;
          align-items: center;

          .payment_method_item_title {
            font-size: 16px;
            font-weight: 600;
            line-height: 140%;
            margin-bottom: 8px;
          }

          .payment_method_item_p {
            font-size: 14px;
            color: #595959;
            font-weight: 400;
            line-height: 140%;
          }
        }
      }
    }

  }
}

.modal_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: var(--color-primary);
  margin-bottom: 42px;
  text-align: center;
}

.modal_info {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 12px;

  .modal_info_p {
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    color: #4860CC;
  }
}

.modal_upload_title {
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.modal_upload_content {
  font-size: 14px;
  color: #8C8C8C;
  font-weight: 400;
  line-height: 140%;
}

.upload_overview {
  width: 100%;
  border: 1px solid #BFBFBF;
  background: #F5F5F5;
  text-align: center;
  padding: 16px 0;
  gap: 20px;
  display: grid;
  border-radius: 12px;
  margin-top: 8px;

  svg {
    width: 100%;
  }
}

.modal_upload_img_container {
  display: grid;
  text-align: center;
  justify-content: center;
  gap: 12px;

  .modal_upload_edit {
    margin: 0 16px;
    gap: 8px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    .modal_upload_edit_p {
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
    }
  }
}