.programs_list {
  li {
    display: flex;
    align-items: center;
    gap: 24px;
    border-bottom: 1px solid #f0f2f7;
    padding: 12px 0;
    &:last-child {
      border-bottom: none;
    }
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #363565;
      &:last-child {
        color: #7980bc;
        font-weight: 400;
        margin-top: 6px;
      }
    }
  }
  .item_heading {
    flex: 1;
  }
}
