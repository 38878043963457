@import "../../assets/sass/abstracts/mixin";

.add_new_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-white);
  border-radius: 6px;
  overflow: hidden;
  padding-top: 120px;
  min-height: 780px;
  position: relative;
  @include mobile {
    min-height: unset;
    padding: 0;
    background-color: unset;
  }
}

.progress_bar {
  width: 100%;
  position: absolute;
  left: 0;
  top: -8px;
  @include mobile {
    display: none;
  }
}

.progress_text {
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #4a9d77;
  transform: translateX(-100%);
  bottom: 0;
  min-width: max-content;
}

.form_inner {
  width: 586px;
  border: 1px solid #949bda;
  border-radius: 6px;
  padding: 30px;
  @include mobile {
    padding: 12px;
    border: none;
    background-color: var(--color-white);
    overflow: auto;
    width: 100%;
  }
}

.form_bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px auto 50px;
  gap: 10px;
  @include mobile {
    margin-top: 50px;
  }
}
