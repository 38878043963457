// @import "//assets/sass/abstracts/mixin";
@import '../../../assets/sass/abstracts/mixin';

.profile_inner {
  display: flex;
  width: 100%;
  gap: 40px;
  & > div {
    flex: 1;
  }
  @include mobile {
    display: block;
  }
}

.profile_heading {
  margin-bottom: 40px;
}

.heading_title {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: var(--color-primary);
  margin-bottom: 16px;
}

.heading_detail {
  display: flex;
  align-items: center;
  gap: 24px;
  @include mobile {
    // flex-wrap: wrap;
    gap: 12px;
    // display: block;
  }
}

.detail_avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
}

.detail_control {
  display: flex;
  gap: 10px;
}
