@import "../../../assets/sass/abstracts/mixin";

.section_analytics {
  display: flex;
  gap: 20px;
  padding-bottom: 50px;
  @include tablet {
    flex-direction: column;
  }
  @include mobile {
    flex-direction: column;
  }
}

.analytics_left {
  flex: 1;

  @include mobile {
    overflow: auto;
  }
}

.left_inner {
  @include mobile {
    min-width: 910px;
  }
}

.analytics_right,
.left_inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.analytics_right {
  width: 100%;
  @include desktop {
    display: flex;
    width: 410px;
  }
}

.bookings_circle {
  width: 300px;
  height: 300px;
  background-color: white;
  border-radius: 50%;
  position: relative;
  // clip-path: inset(20px);
  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    border-radius: 50%;
    border: 20px solid var(--color-green);
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.5;
  }
}

.analytics_card,
.overview_item {
  background: var(--color-white);
  box-shadow: 0px 4px 24px rgba(225, 225, 239, 0.6);
  border-radius: 6px;
  padding: 24px;
  max-width: 100%;
}

.analytics_label {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: var(--color-primary);
  margin-bottom: 24px;
}

.pie_detail {
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: var(--color-primary);
  &.is_red li::before {
    background-color: #e95060;
  }
  li {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-left: 16px;
    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--color-green);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-60%);
    }
    &:last-child::before {
      opacity: 0.5;
    }
  }
  span:last-child {
    font-weight: 600;
  }
}

.analytics_overview {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  li {
    flex: 1;
  }
}

.overview_item {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  .card_info {
    flex: 1;
  }

  .info_title {
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 500;
    font-size: 24px;
    color: var(--color-primary);
  }

  .info_text {
    font-size: 14px;
    color: var(--color-primary);
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
